// Differentials banner(s)
@import '../autoload';

.differentials {
  background-color: $light-grey;
  display: none;
  line-height: 60px;
  margin-bottom: 30px;
  text-align: center;

  @media #{$desktop} {
    display: block;
  }

  p {
    margin-bottom: 0;
  }
}
