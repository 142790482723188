// Products grid across home/category/upsell/related/crosssell
@import '../autoload';

.category-products {
  margin-bottom: 30px;
  position: relative;
  text-align: center;

  a:not(.button) {
    color: inherit;
  }

  &--primary .products-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      flex-basis: calc(100% / #{$smartphone-columns} - 5px);

      @media #{$desktop} {
        flex-basis: calc(100% / #{$desktop-columns} - 15px);
      }

      @media #{$tablet} {
        flex-basis: calc(100% / #{$tablet-columns} - 15px);
      }
    }
  }

  &--secondary {
    background-color: $light-grey;
    padding-bottom: 30px;

    .category-products__title {
      margin-bottom: 30px;
      padding-top: 30px;
    }

    .products-grid .item {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &--carousel {
    @media #{$desktop} {
      .owl-carousel .owl-buttons {
        top: -60px;
      }
    }

    .item {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  @media #{$mobile} {
    .products-grid .owl-buttons {
      background: none;
      padding: 0;
      position: static;

      .owl-next,
      .owl-prev {
        background: none;
        font-size: 1.25rem;
        position: absolute;
        top: 45%;
      }

      .owl-prev {
        left: -15px;
      }

      .owl-next {
        right: -15px;
      }
    }
  }
}

.products-grid,
.products-list {
  margin-bottom: 15px;
  margin-top: 15px;

  .item {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;
    text-align: left;

    &:hover .actions,
    &:hover .adjgiftreg_add_to {
      opacity: 1;
    }

    > h2,
    > div,
    > p {
      flex: 1;
    }

    p {
      margin-bottom: 0;
    }

    .box-image {
      cursor: pointer;
      display: inline-block;
      margin-bottom: 5px;
    }

    .product-name {
      font-size: .875rem;
    }

    .rating-box {
      width: 60px;

      &,
      .rating {
        background-size: 12px;
        height: 12px;
      }

      .rating {
        background-position: 0 -49px;
      }
    }

    .price-box {
      .price-label {
        text-transform: lowercase;
      }

      .old-price {
        font-size: .75rem;
        text-decoration: line-through;
      }

      .special-price {
        font-size: 1.125rem;

        .price {
          color: $blue;
          font-size: 1.125rem;
          font-weight: bold;
        }
      }
    }

    .installments,
    &__bank-slip {
      font-size: .75rem;
    }

    &__more-category {
      border-top: 1px solid $grey-border;
      color: $blue;
      font-size: .75rem;
      font-weight: 400;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-top: 5px;
    }

    .actions {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      transition: .5s opacity;

      .messages {
        display: none !important;
      }

      @media #{$desktop} {
        opacity: 0;
      }

      .button {
        float: left;
        width: calc(50% - 5px);
      }
    }

    .adjgiftreg_add_to {
      align-items: center;
      color: $red;
      display: flex;
      font-size: .75rem;
      font-weight: 400;
      justify-content: center;
      margin-top: 9px;
      opacity: 0;
      text-align: center;
      transition: .5s opacity;

      &:hover span {
        text-decoration: underline;
      }

      .fa {
        font-size: 1.5em;
        margin-right: 5px;
      }
    }
  }
}

// List mode
.category-products .products-list .item {
  padding: 20px 20px 20px 5px;
  position: relative;

  &:not(:last-of-type) {
    border-bottom-color: $blue;
  }

  > div {
    position: static;

    br {
      display: none;
    }

    .new-product,
    .sales {
      top: 19px;
    }

    .sale_img:not(:last-of-type) {
      margin-bottom: 0;
    }

    .new-product {
      left: 19px;
    }

    .bt-buy {
      bottom: 25px;
      left: auto;
      padding: 5px 20px;
      right: 19px;
      width: auto;
    }
  }
}

.box-image {
  position: relative;
}

// Rating
.ratings {
  display: inline-block;
  float: none;
  font-size: 11px;
  line-height: 1.25;
  margin: 7px 0;
}

.rating-box {
  margin-right: 10px;
  width: 100px;

  &,
  .rating {
    background-image: url('#{$images}bkg_rating.svg');
    background-repeat: repeat-x;
    float: left;
    height: 19px;
  }

  .rating {
    background-position: 0 -81px;
  }
}
