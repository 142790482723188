.social-media-buttons {
  $button-size: 60px;
  $badge-size: 20px;
  $popup-width: 400px;
  $popup-height: 280px;

  .social-popup {
    @media #{$mobile} {
      display: none;
    }
  }

  @media #{$desktop} {
    bottom: 20px;
    right: 100px;
  }

  bottom: 70px;
  right: 75px;
  position: fixed;
  z-index: 10;

  .whatsapp {
    color: $whatsapp-green;
    position: relative;

    & > .btn {
      align-items: center;
      background-color: $whatsapp-green;
      border-radius: 50%;
      bottom: 0;
      box-shadow: rgba(7, 94, 84, .24) 1px 6px 24px 0;
      color: $white;
      display: flex;
      font-size: 32px;
      height: $button-size;
      justify-content: center;
      left: 0;
      position: absolute;
      transition-duration: $transition-duration;
      width: $button-size;
      z-index: 15;

      &:hover {
        background-color: $white;
        color: $whatsapp-green;
      }

      &::after {
        background-color: #e82c0c;
        border-radius: 50%;
        bottom: $button-size - $badge-size / 1.25;
        color: $white;
        content: '1';
        display: block;
        font-size: $badge-size / 1.5;
        height: $badge-size;
        left: $button-size - $badge-size / 1.25;
        line-height: $badge-size;
        position: absolute;
        text-align: center;
        transition-duration: $transition-duration / 2;
        width: $badge-size;
      }
    }

    &.viewed > .btn::after {
      transform: scale(0);
      transform-origin: center;
    }

    & > .social-popup {
      opacity: 0;
      transition-duration: $transition-duration;
      visibility: hidden;

      &.active {
        opacity: 1;
        visibility: visible;
      }

      & > a {
        background-image: url('../images/whatsapp-popup-background.png');
        background-repeat: no-repeat;
        background-size: 100%;
        border-radius: 32px;
        bottom: -10px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .5);
        display: block;
        height: $popup-height;
        position: absolute;
        right: -80px;
        width: $popup-width;
      }

      & > button {
        $size: 34px;

        background: $black;
        border-radius: 50%;
        bottom: $popup-height - $size * 1.8;
        color: $white;
        height: $size;
        left: $size;
        opacity: .4;
        position: absolute;
        transition-duration: $transition-duration;
        width: $size;

        &:hover {
          opacity: .6;
        }
      }
    }
  }
}
