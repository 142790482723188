// Ultra-simple column layout
@import '../autoload';

.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -30px;
  margin-right: -30px;

  &__column {
    padding-left: 30px;
    padding-right: 30px;

    @media #{$desktop} {
      flex: 1;
    }

    @media #{$mobile} {
      margin-bottom: 15px;
    }

    @media #{$tablet} {
      flex-basis: 50%;
    }

    @media #{$smartphone} {
      flex-basis: 100%;
    }

    &--bordered {
      border-color: $grey-border;
      border-right-style: solid;
      border-width: thin;

      @media #{$smartphone} {
        border-bottom-style: solid;
        border-right-style: hidden;
        margin-bottom: 15px;
        padding-bottom: 15px;
      }
    }

    &--vertical {
      display: flex;
      flex-direction: column;
    }
  }
}
