// General store titles and texts
@import '../autoload';

.link {
  color: $blue;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

.page-title {
  border-bottom: 1px solid $blue;
  margin-bottom: 15px;
  padding-bottom: 5px;
  text-align: left;

  &__heading,
  h1,
  &__subtitle {
    display: inline-block;
    font-weight: bold;
    margin-bottom: -1px;
    padding-bottom: 5px;
  }

  &__heading,
  h1 {
    color: $blue;
    font-size: 1.25rem;
  }

  &__subtitle {
    color: $grey;
    font-size: .875rem;
  }

  &--center {
    text-align: center;

    .page-title__heading,
    h1 {
      display: block;
    }

    @media #{$smartphone} {
      text-align: left;

      .page-title__subtitle {
        display: none;
      }
    }
  }
}

.subtitle {
  color: $blue;
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 19px;
}

.category-products {
  &__subtitle,
  &__title {
    font-weight: bold;
    margin-bottom: 0;
  }

  &__title {
    color: $blue;
    font-size: 1.875rem;
    margin-bottom: 9px;
  }

  &__subtitle {
    color: $grey;
    font-size: .875rem;
  }
}
