// Newsletter form on store footer
@import '../autoload';


.newsletter {
  background-color: $blue;
  color: $white;
  padding-bottom: 22px;
  padding-top: 22px;

  @media #{$mobile} {
    text-align: center;
  }

  .newsletter__col-left {
    h2 {
      font-size: 1.375rem;
      font-weight: bold;
      margin-bottom: 0;
      margin-top: 0;

      span {
        display: block;
      }
    }
  }

  .newsletter__col-right {
    form {
      background-color: none;
      display: inline-block;
      line-height: initial;
      position: relative;
      width: 100%;

      button,
      input {
        background: $blue;
        color: #040404;
        font-size: 1rem;
        text-transform: none;
      }

      input {
        background: $white;
        border: solid 2px $grey-border;
        border-radius: 50px;
        height: 48px;
        padding: 5px 15px;

        &:focus {
          border-color: $grey-border;
        }

        &::placeholder {
          color: inherit;
        }
      }

      .validation-advice {
        color: $white;
        margin-top: 5px;
      }

      button {
        background-color: $red;
        border: none;
        border-radius: 0 50px 50px 0;
        color: $white;
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 44px;
        padding: 0 15px;
        position: absolute;
        right: 2px;
        text-transform: none;
        top: 2px;

        &:hover {
          background-color: darken($red, 10%);
        }
      }
    }
  }
}
