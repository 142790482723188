// Buttons
@import '../autoload';

.button {
  align-items: center;
  background-color: $red;
  border: 0;
  border-radius: $radius;
  color: $white;
  display: inline-flex;
  font-weight: 400;
  justify-content: center;
  line-height: 30px;
  padding-left: 33px;
  padding-right: 33px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color .5s, color .5s;

  &:hover {
    background-color: darken($red, 10%);
  }

  &--buy,
  &--details {
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    display: inline-block;
    font-size: .75rem;
    padding-left: 2px;
    padding-right: 2px;
  }

  &--buy {
    border-color: $red;

    &:hover {
      border-color: darken($red, 10%);
    }
  }

  &--details {
    background: none;
    border-color: $blue;
    color: $blue;

    &:hover {
      background-color: $blue;
      color: $white;
    }
  }

  &--outline {
    background-color: $white;
    border: 1px solid $blue;
    color: $blue;

    &:hover {
      background-color: $blue;
      border-color: $blue;
      color: $white;
    }
  }

  &--big {
    font-size: 1rem;
    min-height: 40px;
    width: 100%;
  }

  i {
    padding-right: 5px;
  }
}

a.button {
  display: inline-block;

  &:hover,
  &:focus {
    color: $white;
  }
}

.btn-remove {
  color: $black;
  font-size: 1rem;

  &:hover {
    color: $red;
  }
}

.input-text + button {
  background: none;
  border: 0;
  font-weight: normal;

  &:hover {
    background: none;
  }
}

.back-link,
.back-store {
  border: 2px solid $blue;
  border-radius: 8px;
  color: $blue;
  float: left;
  font-size: .875rem;
  font-weight: bold;
  line-height: 30px;
  padding-left: 23px;
  padding-right: 23px;
  text-transform: uppercase;
}

.buttons-set .button,
.title-buttons__container {
  float: right;
}
