// Messages
@import '../autoload';

%message-container {
  box-shadow: $outter-box-shadow;
  font-weight: bold;
  padding: 15px;
}

.messages {
  @extend %message-container;

  background-color: $light-grey;
  margin-bottom: 15px;
  margin-top: 15px;
}

.global-site-notice {
  @extend %message-container;

  background-color: $blue;
  color: $white;
  padding-bottom: 5px;
  text-align: center;
}

%message-status {
  background:  {
    position: left center;
    repeat: no-repeat;
    size: .875rem;
  }
  font-size: .875rem;
  padding:  {
    left: 1.125rem;
    top: 5px;
  }
}

.onestepcheckout-success-msg,
.success-msg {
  @extend %message-status;

  background-image: url('#{$images}ok.svg');
  color: $blue;
}

.onestepcheckout-warning-msg,
.warn-msg {
  @extend %message-status;

  background-image: url('#{$images}caution.svg');
  color: $yellow;
}

.error-msg,
.onestepcheckout-error-msg {
  @extend %message-status;

  background-image: url('#{$images}cancel.svg');
  color: $red;
}
