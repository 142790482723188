// Stylish default Magento elements
@import '../autoload';

.col2-set {
  display: flex;
  justify-content: space-between;

  .col-1,
  .col-2 {
    width: calc(50% - 15px);
  }
}
