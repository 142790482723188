// Breadcrumbs
@import '../autoload';

.breadcrumbs {
  font-size: .8125rem;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: left;

  &__list,
  &__item {
    display: inline-block;
  }

  &__item {
    &:not(:last-of-type)::after {
      content: '>';
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
