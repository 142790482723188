// Badges
@import '../autoload';
$size: 40px;

// Attributes
.sale_img {
  background-color: $blue;
  border-radius: 10px;
  color: $white;
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  margin: 0;
  padding: 0 20px;
  vertical-align: middle;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
}

// Badges "New" and "-n% off"
.new-product,
.sales {
  align-items: center;
  border-radius: 50%;
  color: $white;
  display: flex;
  flex-direction: column;
  font-size: .75rem;
  font-weight: bold;
  height: $size;
  text-align: center;
  top: 5px;
  width: $size;
  z-index: 1;
}

.sales {
  background-color: $red;
  justify-content: space-around;
  padding: 10px 0;
}

.new-product {
  background-color: $blue;
  justify-content: center;
  line-height: $size;
}

.item {
  .new-product,
  .sales {
    position: absolute;
  }

  .sales {
    right: 5px;
  }

  .new-product {
    left: 5px;
  }
}
