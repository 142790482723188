%container {
  margin-left: $container-margin;
  margin-right: $container-margin;
  padding-left: 15px;
  padding-right: 15px;
  width: auto;

  @media #{$smartphone} {
    margin-left: 0;
    margin-right: 0;
  }
}
