// AddTopCart module styles
@import '../autoload';

header .cart {
  position: relative;

  &__sidebar {
    background-color: $white;
    border-radius: 5px;
    box-shadow: $outter-box-shadow;
    padding: 15px;
    position: absolute;
    right: 27px;
    top: 27px;
    transform: scale(0);
    transform-origin: right top;
    transition-duration: .25s;
  }

  @media #{$desktop} {
    &__icon:hover + .cart__sidebar,
    .cart__sidebar:hover,
    .cart__sidebar.active {
      transform: scale(1);
    }
  }
}

.top-cart {
  &__content {
    display: flex;
    flex-direction: column;

    .cart-top-item {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      &:not(:last-of-type):not(:only-of-type) {
        border-bottom: 1px solid $grey-border;
        padding-bottom: 10px;
      }

      &__image {
        min-width: 90px;
        width: 90px;
      }

      &__details {
        flex-grow: 1;
        margin-left: 15px;
        margin-right: 15px;
      }
    }

    .actions {
      white-space: nowrap;
    }
  }
}
