// Custom Bootstrap navbars, used in categories menu
@import '../autoload';

.navbar {
  background-color: $blue;
  border: 0;
  border-radius: 0;
  color: $white;
  font-size: .8125rem;
  margin-bottom: 0;
  min-height: auto;

  @media #{$mobile} {
    > .container {
      margin-left: 0;
      margin-right: 0;
    }
  }

  a {
    color: inherit;
  }

  &-header {
    .navbar-toggle-text,
    .navbar-toggle {
      margin-bottom: 0;
      margin-top: 2px;
    }

    .navbar-toggle-text {
      display: none;
      float: left;
      margin-left: 15px;
      padding: 9px 10px;
      text-transform: uppercase;

      @media #{$mobile} {
        display: inline-block;
      }
    }

    .navbar-toggle .icon-bar {
      background-color: $white;
    }
  }

  &-nav {
    .dropdown {
      font-weight: 400;
      text-transform: uppercase;
      vertical-align: middle;

      &:nth-last-child(2) {
        background-color: $red;

        &:hover {
          background-color: darken($red, 10%);
        }
      }

      &:hover,
      &.open > a {
        background-color: darken($blue, 10%);
      }

      a {
        padding-bottom: 10px;
        padding-top: 10px;
        transition: padding .25s;
        white-space: normal;

        &:hover,
        &:focus {
          background-color: transparent;
          color: inherit;
        }
      }

      &-menu {
        width: 100%;

        > a {
          display: block;
        }
      }
    }

    @media #{$desktop} {
      .dropdown {
        &:last-of-type {
          direction: rtl;

          .dropdown-menu {
            right: 0;

            ul {
              left: auto;
              right: 100%;
            }
          }
        }

        &:hover .dropdown-menu {
          display: block;
        }

        .dropdown-menu {
          width: auto;

          ul {
            background-color: $white;
            border-radius: 0 0 4px 4px;
            box-shadow: $outter-box-shadow;
            display: none;
            left: 100%;
            min-width: 150px;
            position: absolute;
            top: 0;
          }

          li {
            position: relative;

            &:hover {
              > ul {
                display: block;
              }

              > a {
                color: $blue;
                padding-left: 20px;
              }
            }

            a {
              color: $black;
              display: block;
              padding: 5px 15px;
            }
          }
        }
      }
    }

    @media #{$tablet}, #{$desktop} {
      display: table;
      text-align: center;
      width: 100%;

      .dropdown {
        display: table-cell;
        float: none;
      }
    }

    @media #{$smartphone} {
      display: block;
      margin-left: 0;
      margin-right: 0;

      .dropdown {
        display: block;

        &-menu {
          background: none;
          border: 0;
          box-shadow: none;
          display: block;
          position: relative;
          text-align: center;
        }

        .submenu a {
          color: $white;
        }
      }
    }
  }
}

// Override default Boostrap navbar breakpoint
@media #{$mobile} {
  .navbar {
    &-header {
      float: none;
    }

    &-left,
    &-right {
      float: none !important;
    }

    &-toggle {
      display: block;
    }

    &-collapse.collapse {
      border-top: 1px solid #d3e0e9;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
      display: none !important;
      margin-left: -15px;
      margin-right: -15px;
      overflow-x: hidden !important;
      overflow-y: auto !important;
    }

    &-fixed-top {
      border-width: 0 0 1px;
      top: 0;
    }

    &-nav {
      float: none !important;
      margin-top: 7.5px;
    }

    &-nav > li {
      float: none;
    }

    &-nav > li > a {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }

  .collapse.in {
    display: block !important;
  }

  .dropdown-menu {
    border: 0;
    box-shadow: none;
    float: none;
    position: relative;
  }
}
