// Header
@import '../autoload';

// Modules
@import 'topcart';

.header {
  background-color: fade-out($white, .25);
  box-shadow: $outter-box-shadow;
  left: 0;
  position: fixed;
  top: 0;
  transition-duration: .5s;
  width: 100%;
  z-index: 9995;

  &:hover {
    background-color: $white;
  }

  a {
    color: inherit;
  }

  .container {
    align-items: center;
    display: flex;
    grid-gap: 10px;
    padding-bottom: 5px;
    padding-top: 5px;

    @media #{$mobile} {
      display: grid;
      margin: 0;
      padding: 5px 15px;
      width: 100%;

      &::after,
      &::before {
        content: none;
      }

      .header {
        &__logo {
          grid-area: logo;
        }

        &__links {
          grid-area: links;
        }

        &__search {
          grid-area: search;
          margin: 0;
        }
      }

      .cart {
        grid-area: cart;
      }
    }

    @media #{$tablet} {
      grid-template-areas: 'logo links cart' 'search search search';
      grid-template-columns: auto 1fr auto;
    }

    @media #{$smartphone} {
      grid-template-areas: 'logo cart' 'search search';
      grid-template-columns: 1fr auto;

      .header {
        &__logo img {
          height: 54px;
        }

        &__links {
          background-color: $white;
          bottom: 0;
          box-shadow: $box-shadow-top;
          left: 0;
          margin: 0;
          padding: 10px;
          position: fixed;
          width: 100%;
          z-index: 9995;

          .header-block {
            flex-grow: 1;
          }
        }
      }
    }
  }

  &__search {
    flex: 1;
    margin-left: 15px;
    margin-right: 15px;

    .close {
      display: none;
    }

    .search-form {
      position: relative;

      label {
        display: none;
      }

      .input-text {
        border-radius: 2rem;
        font-size: .75rem;
        height: 32px;
        padding-left: 15px;
        text-transform: none;
      }

      .search-button {
        color: $blue;
        font-size: 20px;
        line-height: 30px;
        min-width: 30px;
        padding: 0;
        position: absolute;
        right: 15px;
        top: 0;

        &:hover {
          color: darken($blue, 10%);
        }
      }
    }
  }

  &__links {
    text-align: center;

    .header-block {
      display: inline-block;
      font-size: .75rem;
      font-weight: 400;
      margin-right: 10px;
      text-transform: lowercase;

      a {
        display: inline-block;

        @media #{$desktop} {
          padding-bottom: 10px;
          padding-top: 10px;
        }

        @media #{$mobile} {
          text-align: center;
        }

        @media #{$smartphone} {
          .fa,
          span {
            display: block;
          }
        }

        &:hover span {
          text-decoration: underline;
        }

        .fa {
          color: $blue;
          margin-right: 5px;
        }
      }

      .adjgiftreg {
        color: $red;

        .fa {
          color: inherit;
          vertical-align: bottom;

          @media #{$desktop}, #{$tablet} {
            font-size: 1.5em;
          }
        }
      }
    }
  }

  .cart {
    &__icon {
      $size: 54px;

      background-color: $red;
      border-radius: 50%;
      color: $white;
      display: inline-block;
      line-height: $size;
      position: relative;
      text-align: center;
      transition: background-color .5s;
      width: $size;

      &:hover {
        background-color: darken($red, 10%);
      }

      .fa {
        font-size: 34px;
        vertical-align: middle;
      }

      .badge {
        $size: 20px;

        background-color: $blue;
        line-height: $size;
        min-width: $size;
        padding: 0;
        position: absolute;
        right: -5px;
        top: 0;
      }
    }
  }

  @media #{$smartphone} {
    &__links .header-block a .fa,
    .cart .cart__icon .fa {
      font-size: 20px;
      margin-right: 0;
    }

    &__links {
      display: flex;
      justify-content: space-between;
      margin-left: 5px;
      margin-right: 5px;

      .header-block {
        margin-right: 0;
      }
    }
  }
}
