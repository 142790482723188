// Footer
@import '../autoload';

footer {
  background-color: $light-grey;
  padding-bottom: 30px;
  padding-top: 30px;

  h2 {
    color: $blue;
    font-size: 1.125rem;
    font-weight: bold;
  }

  p {
    color: $black;
    font-size: .75rem;
    font-weight: 400;
    line-height: 1.25rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
  }
}

.footer-description {
  border-bottom: 1px solid $grey-border;
  column-gap: 30px;
  font-weight: 400;
  padding-bottom: 30px;
  padding-top: 30px;

  @media #{$tablet} {
    columns: 2;
  }

  @media #{$desktop} {
    columns: 3;
  }
}

.copyright {
  background-color: $white;
  color: $black;
  font-size: .625rem;
  margin-bottom: 0;
  padding: 15px 0 0;

  &__logo {
    padding-bottom: 30px;

    img {
      height: 50px;
    }
  }
}

@media #{$desktop} {
  .copyright {
    padding-bottom: 15px;

    &__text,
    &__logo {
      line-height: 50px;
    }

    &__logo {
      flex: initial;
      text-align: right;
    }
  }
}

@media #{$mobile} {
  footer,
  .copyright {
    text-align: center;
  }
}
