// Base HTML styles
@import '../autoload';

html,
body {
  overflow-x: hidden;
}

html {
  font-size: 16px;

  @media #{$smartphone} {
    font-size: 14px;
  }
}

body {
  color: $black;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  padding-top: 83px;

  @media #{$tablet} {
    padding-top: 125px;
  }

  @media #{$smartphone} {
    padding-top: 106px;

    > .zopim {
      bottom: 70px !important; // override inline styles
      right: 15px !important; // override inline styles
    }
  }
}

article,
section {
  margin-bottom: 30px;
}

a {
  color: $blue;
  transition: .3s color;

  &:hover,
  &:focus {
    outline: 0;
    text-decoration: none;
  }

  &:hover {
    color: $blue;
  }

  &:focus {
    color: $black;
  }
}

img {
  max-width: 100%;
}

ol,
ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

.no-display {
  display: none;
}

.container {
  @extend %container;
}

.zopim {
  right: inherit !important;
  left: 10px !important;
}
