.notice-cookie {
  transition: .1s ease-in-out;
  position: fixed;
  background-color: white;
  color: black;
  bottom: 25px;
  left: 10px;
  right: 10px;
  padding: 10px 20px;
  border-radius: 12px;
  margin: 0 auto;
  font-size: 13px;
  overflow: hidden;
  width: calc(100% - 20px);
  z-index: 9999;
  box-shadow: 0 0 10px #aaa;

  .notice-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$mobile} {
      justify-content: center;
      flex-direction: column;

      .actions {
        margin-top: 10px;
      }
    }
  }

  .button {
    background-color: red;
    color: white;
    padding: 5px 20px;
    border-color: black;
    border-radius: 8px;
  }
}

