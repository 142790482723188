// Carousel(s)
@import '../autoload';

.category-products .owl-carousel,
.manufacturers .owl-carousel {
  position: relative;

  .owl-buttons {
    background-color: $light-grey;
    border-radius: 30px;
    padding: 4px;
    position: absolute;
    right: 0;
    text-align: center;

    .owl-next,
    .owl-prev {
      background-color: $white;
      border-radius: 50%;
      color: $grey;
      display: inline-block;
      font-size: .75rem;
      line-height: 30px;
      transition: color .25s;
      width: 30px;

      &:hover {
        color: $black;
      }
    }

    .owl-prev {
      margin-right: 2px;
    }

    .owl-next {
      margin-left: 2px;
    }
  }
}

.main-container .flexslider {
  border: 0;
  box-shadow: none;
  margin-bottom: 0;

  ol.flex-control-nav {
    bottom: 15px;

    li a {
      background-color: $white;
      border: 2px solid $white;

      &.flex-active {
        background-color: $purple;
      }
    }
  }

  ul.flex-direction-nav li a {
    opacity: .8;

    &.flex-prev {
      left: 30px;
    }

    &.flex-next {
      right: 30px;
    }
  }
}

.category-products--secondary .owl-carousel .owl-buttons {
  background: none;
  right: 5px;
  top: -60px;
}

@media #{$tablet} {
  .main-container .flexslider ul.flex-direction-nav li a {
    &.flex-next {
      right: 15px;
    }

    &.flex-prev {
      left: 15px;
    }
  }
}

@media #{$smartphone} { 
  .nav-tabs {
    margin-top: 15px;    
  }

  .nav-tabs a {
    font-size: 1rem;
  }

  .main-container .flexslider ol.flex-control-nav {
    bottom: 0px;
  }
}
