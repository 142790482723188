// Forms
@import '../autoload';

form {
  input:not([type='checkbox']):not([type='radio']),
  select,
  textarea {
    background-color: $white;
    border: 1px solid $grey;
    border-radius: $radius;
    color: $black;
    outline: none;
    padding: 5px;
    transition: border .5s;
    width: 100%;

    &:active,
    &:focus {
      border-color: $blue;
    }

    &:invalid {
      border-color: $red;
    }

    &[readonly],
    &[disabled] {
      background-color: $light-grey;
    }

    &.input--flat {
      border: 1px solid transparent;
      border-radius: $radius;
      min-height: 40px;
      text-align: center;

      &:focus {
        border-color: $blue;
      }
    }
  }

  input {
    &[type='checkbox'],
    &[type='radio'] {
      display: inline-block;
      margin-right: 5px;
      margin-top: 0;
    }
  }

  dl {
    dd,
    dt {
      clear: both;
    }
  }

  label {
    font-size: .875rem;
    font-weight: normal;
    margin-bottom: 0;
  }

  select {
    padding-right: 15px;
  }

  .required em,
  p.required {
    color: $red;
  }

  .required em {
    float: right;
  }

  .fields,
  .wide,
  .one-field {
    clear: both;
    display: inline-block;
    margin: 0 0 15px;
    width: 100%;
  }

  .fields .field,
  .two-fields {
    float: left;
    width: calc(50% - 3px);

    &:last-of-type:not(:only-of-type) {
      margin-left: 6px;
    }
  }

  // OneStepCheckout based forms
  fieldset,
  .fieldset {
    margin-bottom: 30px;

    ul {
      margin-bottom: 0;
    }

    .legend {
      font-size: 1.125rem;
      margin: 0 0 15px;
    }

    p {
      font-size: .875rem;
      font-weight: 400;
    }
  }

  .form-list li {
    margin: 0 0 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .input-box .v-fix {
    display: inline-block;
    max-width: calc(50% - 5px);
  }

  // Validation
  .validation-advice {
    color: $red;
  }

  .validation-failed:not(button),
  .validation-passed:not(button) {
    background-position: calc(100% - 5px);
    background-repeat: no-repeat;
    background-size: 15px;
  }

  .validation-passed:not(button) {
    background-image: url('#{$images}ok.svg');
    border-color: $blue;
  }

  .validation-failed:not(button) {
    background-image: url('#{$images}cancel.svg');
    border-color: $red;
  }
}

// General elements
select {
  appearance: none;
  background-color: darken($white, .1);
  background-image: url('#{$images}/select_arrows.svg');
  background-position: calc(100% - 5px);
  background-repeat: no-repeat;
  background-size: 6px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 15px;
}

.buttons-set {
  p {
    font-size: .75rem;
  }

  &--multiple {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 15px;
    margin-bottom: 30px;

    @media #{$smartphone} {
      grid-auto-flow: row;
    }
  }
}
