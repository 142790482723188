#dealer-info {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $blue;
  margin: 0;
  border-bottom: 2px solid $blue;

  span {
    font-size: 0.7rem;
    margin-right: 10px;
  }

  .dealer-name {
    font-weight: 700;
    text-transform: uppercase;
  }

  a {
    font-size: 0.8rem;
    font-weight: 700;

    &:hover {
      opacity: 0.9;
    }
  }

  @media #{$mobile} {
    padding: 5px;
    flex-direction: column;
    gap: 10px;
  }
}

#dealers-list {
  form {
    width: 100%;
    max-width: 350px;
    margin: 30px auto;

    label {
      line-height: 1rem;
    }

    select {
      margin: 10px 0;
    }

    .button {
      background-color: $blue;
      color: $white;

      &:hover {
        opacity: .9;
      }
    }
  }
}
